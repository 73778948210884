import { BadgeCheckIcon, ChipIcon } from "@heroicons/react/solid";
import React from "react";
import { consulting } from "../data";

export default function Consulting() {
  return (
    <section id="consulting">
      <div className="container px-5 py-20 mx-auto">
        <div className="text-center mb-4">
          <ChipIcon className="w-10 inline-block mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
            Consulting Services
          </h1>
          <p className="text-left leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            If you are an executive running a technology organisation who needs help with technical matters and software development I can help with:
          </p>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            <ul className="list-decimal text-left px-4 py-4">
              <li>Technology review</li>
              <li>Technical strategy</li>
              <li>Technical leadership</li>
              <li>System or Code review</li>
              <li>Team and individual coaching</li>
            </ul>
          </p>
        </div>
        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          {consulting.map((offer) => (
            <div key={offer.key} className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-800 rounded flex p-4 h-full items-center">
                <BadgeCheckIcon className="text-blue-400 w-6 h-6 flex-shrink-0 mr-4" />
                <div>
                  <p className="title-font font-medium text-white ">
                    {offer.title}
                  </p>
                  <p className="text-base mb-4">
                    {offer.description}
                  </p>
                  <a  href={offer.buy}
                    className="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">
                    {offer.cta}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}