

export const consulting = [
  {
    key: 1,
    title: "One-Off Consulting.",
    description: "A 30 minute one-off consulting call. During which I'll discuss your problem with you and provide actionable advice regarding your challenge.",
    cta: "Book Now",
    buy: "https://tidycal.com/johncrickett/30-minute-consultation"
  },
  {
    key: 2,
    title: "Bespoke Projects.",
    description: "I'll take on a bespoke project for you either fixed price or on a daily rate. We'll disccus the challenge you have on a discovery call and I'll provide a proposal if it's something I can help with.",
    cta: "Book a Discover Call",
    buy: "https://tidycal.com/johncrickett/discovery-call"
  }
];

export const mentoring = [
  {
    key: 1,
    title: "One off 30 minute mentoring session.",
    description: "I'll jump on a call with you and offer my feedback on whatever you'd like to discuss.",
    cta: "Book Now",
    buy: "https://tidycal.com/johncrickett/30-minute-mentoring"
  },
  {
    key: 2,
    title: "One off 60 minute mentoring session.",
    description: "I'll jump on a call with you and offer my feedback on whatever you'd like to discuss.",
    cta: "Book Now",
    buy: "https://tidycal.com/johncrickett/60-minute-mentoring"
  }
  // {
  //   key: 3,
  //   title: "Regular fortnighly 30 minute session.",
  //   description: "I'll be available to help. Up to four calls per month and unlimited advice asynchronously, reponses within 24 hours.",
  //   cta: "Sign Up Now",
  //   buy: ""
  // },
  // {
  //   key: 4,
  //   title: "Regular fortnighly 60 minute session.",
  //   description: "I'll be available to help. Up to four calls per month and unlimited advice asynchronously, reponses within 24 hours.",
  //   cta: "Sign Up Now",
  //   buy: ""
  // }
];