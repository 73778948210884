import React from "react";

import { MailIcon, UsersIcon } from "@heroicons/react/solid";


export default function Contact() {


  return (
    <section id="contact" className="relative">
      <div className="container px-5 py-10 mx-auto text-center">
        <UsersIcon className="w-10 inline-block mb-4" />
        <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-12">
          Contact Me
        </h1>

        <div className="flex flex-wrap m-4">
            <div className="p-4  w-full">
              <div className="h-full bg-gray-800 bg-opacity-40 p-8 rounded">
                <MailIcon className="block w-8 text-gray-500 mb-4" />
                <p className="leading-relaxed mb-6">
                  You can contact me through <a href="https://www.linkedin.com/in/johncrickett/" target='_blank' rel="noopener noreferrer">LinkedIn</a> or <a href="https://twitter.com/johncrickett" target='_blank' rel="noopener noreferrer">Twitter</a>.
                </p>
                <div className="flex justify-center">
                  <div className="p-4">
                    <a href="https://www.linkedin.com/in/johncrickett/"
                      target='_blank'
                      rel="noopener noreferrer"
                    >
                      <img src="./linkedin.png" alt="LinkedIn Logo" />
                    </a>
                  </div>
                  <div className="p-4">
                    <a href="https://twitter.com/johncrickett"
                      target='_blank'
                      rel="noopener noreferrer"
                    >
                      <img src="./twitter.png" alt="Twitter Logo" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
}
