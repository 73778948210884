import React from "react";
import About from "./components/About";
import Contact from "./components/Contact";
import Consulting from "./components/Consulting";
import Mentoring from "./components/Mentoring";
import Navbar from "./components/Navbar";


export default function App() {
  return (
    <main className="text-gray-400 bg-gray-900 body-font">
      <Navbar />
      <About />
      <Consulting />
      <Mentoring />
      {/* <Skills />
      <Projects /> */}
      <Contact />
    </main>
  );
}
