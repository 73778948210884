import { UsersIcon, UserGroupIcon } from "@heroicons/react/solid";
import React from "react";
import { mentoring } from "../data";

export default function Mentoring() {
  return (
    <section id="mentoring">
      <div className="container px-5 py-10 mx-auto">
        <div className="text-center mb-20">
          <UserGroupIcon className="w-10 inline-block mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
            Mentoring Services
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            I am available as a mentor, one off sessions are $150 per half hour ad-hoc.
          </p>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            Please contact me if you want to arrange a regular or block booking.
          </p>
        </div>
        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          {mentoring.map((offer) => (
            <div key={offer.key} className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-800 rounded flex p-4 h-full items-center">
                <UsersIcon className="text-blue-400 w-6 h-6 flex-shrink-0 mr-4" />
                <div>
                  <p className="title-font font-medium text-white">
                    {offer.title}
                  </p>
                  <p className="text-base mb-4">
                    {offer.description}
                  </p>
                  <a  href={offer.buy}
                    className="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">
                    {offer.cta}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}