import React from "react";

export default function About() {
  return (
    <section id="about">
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
            Hi, I'm John Crickett.
          </h1>
          <p className="mb-8 leading-relaxed">
            I've been in software development for 27 years. During that time I have lead software developement teams as
            a VP Engineering at a VC backed start-up, Global Head of Software Development level in a financial institution and as as an Engineering Manager.
          </p>
          <p className="mb-8 leading-relaxed">
            I've had a somewhat not traditional career, founding a couple of businesses, working as a senior manager and as a Staff+ software engineer.
          </p>
          <p className="mb-8 leading-relaxed">
            I'm currently working as a Software Architect for a large multinational telecommunications company where my focus is enabling software development teams to be more productive.
          </p>
          {/* <p className="mb-8 leading-relaxed">
            Recently there has been some TypeScript/JavaScript, React and Next.js on the frontend with Node.js and Express on the backend. Deployed on AWS using CDK.
          </p>
          <p className="mb-8 leading-relaxed">
            For most of my career before that I worked in C++. I'm coming the full circle now and currently learning Rust.
          </p>
          <p className="mb-8 leading-relaxed">
            I have a passion to help individuals, teams and departments reach their full potential.
          </p> */}
          {/* <p className="mb-8 leading-relaxed">
            I am on a mission to level up one million software developers!
          </p>
          <p className="mb-8 leading-relaxed">
            I’m doing that by:
            <ol>
              <li>1. Helping you improve your coding skills through <a href = "https://codingchallenges.fyi">Coding Challenges</a> that have you building real applications.</li>
              <li>2. Helping you improve your software development and soft skills with posts and articles about software development.</li>
            </ol>
          </p> */}
          <p className="mb-8 leading-relaxed">
            I offer the following services:
          </p>

          <div className="flex justify-center">
            <a
              href="#consulting"
              className="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg">
              Consulting
            </a>
            <a
              href="#mentoring"
              className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
              Mentoring
            </a>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src="./undraw_programming.svg"
          />
        </div>
      </div>
    </section>
  );
}
